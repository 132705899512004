<!-- 编辑弹窗 -->
<template>
  <div>
    <a-modal
      :width="550"
      :visible="visible"
      :confirm-loading="loading"
      :title="isUpdate ? '修改' : '新建'"
      :body-style="{ paddingBottom: '8px' }"
      @update:visible="updateVisible"
      @ok="save"
    >
      <a-form
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ md: { span: 5 }, sm: { span: 24 } }"
        :wrapper-col="{ md: { span: 19 }, sm: { span: 24 } }"
      >
        <a-row :gutter="16">
          <a-col :sm="24" :xs="24">
            <a-form-item label="字段类型:" name="type">
              <a-select
                :disabled="Disabled || isDisabled"
                :value="form.type"
                placeholder="请选择类型"
                allow-clear
                @change="selectTypeChange"
              >
                <a-select-option
                  v-for="item in typeList"
                  :key="item.code"
                  :value="item.code"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24">
            <a-form-item label="字段名称:" name="label">
              <a-input
                :disabled="Disabled || isDisabled"
                v-model:value="form.label"
                placeholder="请输入名称"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24">
            <a-form-item label="字段描述:" name="placeholder">
              <a-input
                :disabled="Disabled || isDisabled"
                v-model:value="form.placeholder"
                placeholder="请输入提示语"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" v-if="form.type === '单行文本'">
            <a-form-item label="默认值:" name="defaultVal">
              <a-input
                v-model:value="form.defaultVal"
                placeholder="请输入默认值"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24">
            <a-form-item label="错误提示:" name="errMsg">
              <a-input
                :disabled="Disabled || isDisabled"
                v-model:value="form.errMsg"
                placeholder="请输入错误提示"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" v-if="form.type === '单行文本'">
            <a-form-item label="最少字符数:" name="minCount">
              <a-input-number
                style="width: 100%"
                :min="0"
                v-model:value="form.minCount"
                placeholder="请输入最少字符数"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" v-if="form.type === '单行文本'">
            <a-form-item label="最多字符数:" name="maxCount">
              <a-input-number
                style="width: 100%"
                :min="0"
                v-model:value="form.maxCount"
                placeholder="请输入最多字符数"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" v-if="form.type === '数字'">
            <a-form-item label="最小值:" name="minCount">
              <a-input-number
                style="width: 100%"
                :min="0"
                v-model:value="form.minCount"
                placeholder="请输入最小值"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" v-if="form.type === '数字'">
            <a-form-item label="最大值:" name="maxCount">
              <a-input-number
                style="width: 100%"
                :min="0"
                v-model:value="form.maxCount"
                placeholder="请输入最大值"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" v-if="form.type === '日期'">
            <a-form-item label="最小日期:" name="minDate">
              <a-date-picker
                style="width: 100%"
                v-model:value="form.minDate"
                placeholder="请选择最小日期"
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" v-if="form.type === '日期'">
            <a-form-item label="最大日期:" name="maxDate">
              <a-date-picker
                style="width: 100%"
                v-model:value="form.maxDate"
                placeholder="请选择最大日期"
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" v-if="form.type === '日期'">
            <a-form-item label="展现形式:" name="dateDisplay">
              <a-select
                v-model:value="form.dateDisplay"
                placeholder="请选择展现形式"
                allow-clear
              >
                <a-select-option
                  v-for="item in dateDisplayList"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :sm="24" :xs="24">
            <a-form-item label="排序:" name="sortNumber">
              <a-input-number
                style="width: 100%"
                :min="0"
                v-model:value="form.sortNumber"
                placeholder="请输入排序"
                allow-clear
              />
            </a-form-item>
          </a-col> -->
          <!-- <a-col
            :sm="24"
            :xs="24"
            v-if="
              ['单项选择', '多项选择', '下拉框', '标签'].includes(form.type)
            "
          >
            <a-form-item label="标题:" name="title">
              <a-input
                v-model:value="form.title"
                placeholder="请输入标题"
                allow-clear
              />
            </a-form-item>
          </a-col> -->
          <a-col
            :sm="24"
            :xs="24"
            v-if="
              ['单项选择', '多项选择', '下拉框', '标签', '团队'].includes(
                form.type
              )
            "
          >
            <a-form-item label="选项:" name="listData">
              <a-radio-group
                v-model:value="listDataType"
                placement="row"
                @change="listDataTypeChange"
              >
                <a-radio value="custom">自定义</a-radio>
                <a-radio value="dataSource">数据源</a-radio>
              </a-radio-group>
              <div v-if="listDataType === 'custom'">
                <div v-if="listData.length !== 0">
                  <a-form-item>
                    <a-input
                      v-for="(item, index) in listData"
                      :key="index"
                      v-model:value="item.label"
                      placeholder="请输入选项(30字以内)"
                      allow-clear
                      style="margin-bottom: 8px"
                    >
                      <template #prefix>
                        <div @click="removeListData(item, index)">
                          <minus-circle-outlined style="color: red" />
                        </div>
                      </template>
                    </a-input>
                  </a-form-item>
                </div>
                <div>
                  <span style="margin-right: 20px" @click="addListData"
                    ><plus-circle-outlined
                      style="color: #13b0f0; margin-right: 5px"
                    /><span style="color: #13b0f0; cursor: pointer"
                      >添加单个选项</span
                    ></span
                  >
                  <span
                    v-if="
                      !isAddOther &&
                      ['单项选择', '多项选择', '团队'].includes(form.type)
                    "
                    @click="addOther"
                    ><plus-circle-outlined
                      style="color: #13b0f0; margin-right: 5px"
                    /><span style="color: #13b0f0; cursor: pointer"
                      >添加&#8968;其他&#8971;选项</span
                    ></span
                  >
                </div>
              </div>
              <!-- 数据源 -->
              <div v-if="listDataType === 'dataSource'">
                <a-select
                  v-model:value="form.dataSource"
                  placeholder="请选择数据源"
                  allow-clear
                >
                  <a-select-option
                    v-for="(item, index) in dataSetList"
                    :key="index"
                    :value="item.dictDataCode"
                  >
                    {{ item.dictDataName }}
                  </a-select-option>
                </a-select>
              </div>
            </a-form-item>
          </a-col>
          <a-col
            :sm="24"
            :xs="24"
            v-if="form.type === '单张图片' || form.type === '多张图片'"
          >
            <a-form-item label="示例图:" name="sampleGraph">
              <a-upload
                list-type="picture-card"
                v-model:file-list="sampleGraphList"
                @preview="handlePreview"
                multiple
                :customRequest="({ file }) => uploadFile(file, 'sampleGraph')"
                @remove="removeFile"
              >
                <div v-if="sampleGraphList.length < (form.maxCount || 1)">
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                :bodyStyle="{ paddingTop: '50px' }"
                @cancel="previewVisible = false"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" v-if="form.type === '多张图片'">
            <a-form-item label="最大上传数:" name="maxCount">
              <a-input-number
                style="width: 100%"
                :min="0"
                v-model:value="form.maxCount"
                placeholder="请输入最大上传数"
                allow-clear
              />
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" v-if="form.type === '描述'">
            <a-form-item label="图片描述:" name="photoDesc">
              <a-upload
                list-type="picture-card"
                v-model:file-list="photoDescList"
                @preview="handlePreview"
                multiple
                :customRequest="({ file }) => uploadFile(file, 'photoDesc')"
                @remove="removeFile"
              >
                <div v-if="photoDescList.length < 1">
                  <plus-outlined />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                :bodyStyle="{ paddingTop: '50px' }"
                @cancel="previewVisible = false"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
          </a-col>
          <!-- <a-col :sm="24" :xs="24" v-if="form.type === '团队'">
            <a-form-item label="数据源:" name="dataSource">
              <a-select
                v-model:value="form.dataSource"
                placeholder="请选择数据源"
                allow-clear
              >
                <a-select-option
                  v-for="item in dataSetList"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col :sm="24" :xs="24" v-if="form.type !== '描述'">
            <a-form-item label="自动填充字段:" name="autoFill">
              <a-select
                v-model:value="form.referrerFieldId"
                placeholder="请选择自动填充字段"
                allow-clear
                :options="autoFillList"
                @change="autoFillChange"
              >
                <!-- <a-select-option
                  v-for="item in autoFillList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option> -->
              </a-select>
            </a-form-item>
          </a-col>
          <a-col
            :sm="24"
            :xs="24"
            v-if="['单张图片', '健康码'].includes(form.type)"
          >
            <a-form-item label="上传设置:" name="uploadType">
              <a-select
                v-model:value="form.uploadType"
                placeholder="请选择上传设置"
                allow-clear
              >
                <a-select-option
                  v-for="item in uploadTypeList"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" v-if="form.type === '健康码'">
            <a-form-item label="所属省份:" name="provice">
              <a-select
                v-model:value="form.provice"
                placeholder="请选择所属省份"
                allow-clear
                @change="proviceChange"
              >
                <a-select-option
                  v-for="item in proviceList"
                  :key="item.value"
                  :value="item.label"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" v-if="form.type === '健康码'">
            <a-form-item label="健康码小程序:" name="miniProgram">
              <a-select
                v-model:value="form.miniProgram"
                placeholder="请选择健康码小程序"
                allow-clear
              >
                <a-select-option
                  v-for="item in miniProgramList"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" v-if="form.type !== '描述'">
            <a-form-item label="是否必填:" name="verify">
              <!-- <a-select
                v-model:value="form.verify"
                placeholder="请选择"
                allow-clear
              >
                <a-select-option :value="null"> 请选择 </a-select-option>
                <a-select-option :value="false"> 否 </a-select-option>
                <a-select-option :value="true"> 是 </a-select-option>
              </a-select> -->
              <a-switch
                v-model:checked="form.verify"
                :unCheckedValue="false"
                :checkedValue="true"
              ></a-switch>
            </a-form-item>
          </a-col>
          <a-col :sm="24" :xs="24" v-if="form.type !== '描述'">
            <a-form-item label="是否隐藏:" name="hide">
              <a-switch
                v-model:checked="form.hide"
                :unCheckedValue="false"
                :checkedValue="true"
              ></a-switch>
            </a-form-item>
          </a-col>
          <a-col
            :sm="24"
            :xs="24"
            v-if="
              ['单行文本', '数字', '手机号', '身份证号'].includes(form.type)
            "
          >
            <a-form-item label="去重校验:" name="distinct">
              <a-switch
                v-model:checked="form.distinct"
                :unCheckedValue="false"
                :checkedValue="true"
              ></a-switch>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import * as dictApi from '@/api/dict/dictData.js'
import * as corpUserApi from '@/api/corp/corpUser.js'
import regions from '@/utils/regions.js'
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
import { createVNode } from 'vue'
import { Modal } from 'ant-design-vue'
import setting from '@/config/setting'
function getBase64(pointPhoto) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(pointPhoto)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'formFieldEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    formId: Number,
    // 修改回显的数据
    data: Object,
    corpId: Number,
    fieldList: Array
  },
  components: {
    MinusCircleOutlined,
    PlusCircleOutlined,
    PlusOutlined
  },
  data() {
    return {
      dateDisplayList: ['年', '年月', '年月日', '日历样式'],
      uploadTypeList: ['拍照或相册', '仅拍照', '仅拍照带水印'],
      listData: [],
      isPetition: false,
      isAddOther: false,
      previewVisible: false,
      sampleGraphList: [],
      photoDescList: [],
      proviceList: regions.provinceData,
      miniProgramList: [],
      uploadUrl: setting.uploadUrl,
      previewImage: '',
      isDisabled: false,
      // 表单数据
      form: { type: '' },
      // 编辑弹窗表单验证规则
      rules: {
        label: [
          {
            required: true,
            message: '请输入字段名称',
            type: 'string',
            validateTrigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      autoFillList: [],
      typeList: [
        {
          code: '单行文本',
          name: '单行文本'
        },
        {
          code: '多行文本',
          name: '多行文本'
        },
        {
          code: '数字',
          name: '数字'
        },
        {
          code: '日期',
          name: '日期'
        },
        {
          code: '时间',
          name: '时间'
        },
        {
          code: '手机号',
          name: '手机号'
        },
        {
          code: '单项选择',
          name: '单项选择'
        },
        {
          code: '多项选择',
          name: '多项选择'
        },
        {
          code: '下拉框',
          name: '下拉框'
        },
        {
          code: '地理位置',
          name: '地理位置'
        },
        {
          code: '单张图片',
          name: '单张图片'
        },
        {
          code: '多张图片',
          name: '多张图片'
        },
        {
          code: '省市区',
          name: '省市区'
        },
        {
          code: '涂写签名',
          name: '涂写签名'
        },
        {
          code: '标签',
          name: '标签'
        },
        {
          code: '描述',
          name: '描述'
        },
        {
          code: '健康码',
          name: '健康码'
        },
        {
          code: '身份证号',
          name: '身份证号'
        },
        {
          code: '团队',
          name: '团队'
        }
      ],
      dataSetList: [],
      action: '',
      listDataType: 'custom'
    }
  },
  watch: {
    // data() {
    //   // if (this.data) {
    //   //   this.form = this.data
    //   //   this.isUpdate = true
    //   //   console.log('form', this.form, this.formId)
    //   // } else {
    //   //   this.form = { formId: this.formId, minCount: 1, maxCount: 500 }
    //   //   this.isUpdate = false
    //   // }
    // }
    corpId() {
      this.getAutoFillData()
    }
  },
  mounted() {
    console.log('fieldEditCorpId', this.corpId)
    this.getDataSetList()
    this.getAutoFillData()
  },
  computed: {
    Disabled() {
      if (!this.form.custom && this.isUpdate) {
        return true
      }
      return false
    }
  },
  methods: {
    getAutoFillData() {
      let params = {
        sort: 'sortNumber',
        order: 'asc',
        limit: 1000,
        page: 1,
        formId: 'corp-' + this.corpId
      }
      params = Object.assign(params, this.where)
      corpUserApi
        .fieldList(params)
        .then((res) => {
          if (res.code === 0) {
            const filterArr = res.data.filter((item) => {
              return item.enable
            })
            const resultArr = filterArr.map((item) => {
              return {
                label: item.label,
                value: item.fieldId,
                type: item.type
              }
            })
            resultArr.unshift({ label: '请选择', value: '', type: '单行文本' })
            this.autoFillList = resultArr
            // this.initAutoFillStr(resultArr)
            console.log('filterArr', filterArr, resultArr, this.autoFillList)
            // this.autoFillList = [resultArr.unshift({label:'请选择',value:''})]
            // console.log('autoFillList',this.autoFillList)
          } else {
            this.$error(res.msg)
          }
        })
        .catch((error) => {
          this.$error(error.message)
        })
    },
    autoFillChange(e, option) {
      this.form.type = option.type
      this.initFormData(this.form.type)
    },
    getDataSetList() {
      dictApi
        .getDictData({ dictCode: 'dataSource' })
        .then((res) => {
          if (res.code === 0) {
            this.dataSetList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 选项单选按钮变化上处理事件
    listDataTypeChange(e) {
      this.listDataType = e.target.value
    },
    resetForm() {
      this.form = {
        dataSource: '',
        maxCount: null,
        minCount: null,
        label: '',
        placeholder: '',
        errMsg: '',
        defaultValue: '',
        type: '',
        verify: false,
        hide: false,
        distinct: false,
        listData: '',
        minDate: '',
        maxDate: '',
        dateDisplay: '年月日',
        uploadType: '拍照或相册',
        sampleGraph: '',
        photoDesc: '',
        provice: '',
        miniProgram: '',
        dataScope: '',
        referrerFieldId: ''
      }
      this.listData = []
      this.photoDescList = []
      this.sampleGraphList = []
    },
    addField(type, action) {
      this.resetForm()
      this.form.type = type
      this.action = action
      this.isUpdate = false
      this.initFormData(this.form.type)
    },
    initForm(formData, action) {
      this.form = {}
      if (action === 'edit') {
        this.isUpdate = true
        this.action = action
      } else {
        this.isUpdate = false
        this.action = action
      }
      this.form = formData
      this.initPhotoList()
      this.initListData()
    },
    initPhotoList() {
      if (this.form.sampleGraph) {
        this.sampleGraphList = this.strToArr(this.form.sampleGraph).map(
          (item, index) => {
            return {
              status: 'done',
              name: 'image.png',
              url: item,
              uid: index
            }
          }
        )
      }
      if (this.form.photoDesc) {
        this.photoDescList = this.strToArr(this.form.photoDesc).map(
          (item, index) => {
            return {
              name: 'image.png',
              status: 'done',
              uid: index,
              url: item
            }
          }
        )
      }
    },
    initListData() {
      if (this.form.dataSource) {
        this.listDataType = 'dataSource'
        this.listData = []
      } else {
        const listArr = this.strToArr(this.form.listData)
        this.listData = listArr.map((item, index) => {
          return {
            label: item,
            id: index
          }
        })
      }
    },
    proviceChange(e) {
      this.initMiniProgramData(e)
    },
    initMiniProgramData(provice) {
      const arr = ['国家政务服务平台']
      if (provice === '北京市') {
        arr.unshift('北京健康宝')
      }
      if (provice === '广西壮族自治区') {
        arr.unshift('桂人助')
      }
      if (provice === '辽宁省') {
        arr.unshift('辽事通码')
      }
      if (provice === '江苏省') {
        arr.unshift('苏服办')
      }
      if (provice === '江西省') {
        arr.unshift('赣通码')
      }
      if (provice === '湖北省') {
        arr.unshift('湖北健康码', '武汉战疫')
      }
      if (provice === '重庆') {
        arr.unshift('渝康码')
      }
      if (provice === '甘肃省') {
        arr.unshift('甘肃健康码')
      }
      if (provice === '云南省') {
        arr.unshift('云南健康码')
      }
      if (provice === '广东省') {
        arr.unshift('粤省事', '穗康', '深i您')
      }
      if (provice === '陕西省') {
        arr.unshift('陕西健康码', '西安一码用')
      }
      if (provice === '福建省') {
        arr.unshift('福建八闽健康码', '福建健康码')
      }
      if (provice === '海南省') {
        arr.unshift('码上办事')
      }
      if (provice === '吉林省') {
        arr.unshift('吉事办')
      }
      if (provice === '山东省') {
        arr.unshift('山东电子健康通行卡')
      }
      if (provice === '湖南省') {
        arr.unshift('湖南省居民健康卡', '我的长沙')
      }
      if (provice === '上海') {
        arr.unshift('随申办')
      }
      if (provice === '西藏自治区') {
        arr.unshift('藏易通')
      }
      if (provice === '黑龙江省') {
        arr.unshift('龙江健康码')
      }
      if (provice === '安徽省') {
        arr.unshift('皖事通')
      }
      if (provice === '四川省') {
        arr.unshift('四川天府健康通')
      }
      if (provice === '贵州省') {
        arr.unshift('贵州健康码')
      }
      if (provice === '浙江省') {
        arr.unshift('甬行码')
      }
      if (provice === '天津') {
        arr.unshift('津心办')
      }
      if (provice === '宁夏回族自治区') {
        arr.unshift('我的宁夏-健康码')
      }
      if (provice === '河北省') {
        arr.unshift('河北健康码')
      }
      if (provice === '新疆维吾尔自治区') {
        arr.unshift('新疆政务')
      }
      if (provice === '内蒙古自治区') {
        arr.unshift('蒙健康')
      }
      this.miniProgramList = arr
      this.form.miniProgram = arr[0]
    },
    initFormData(type) {
      if (type === '健康码') {
        this.form.label = '健康码'
        this.form.placeholder = '请上传健康码截图'
        this.isDisabled = true
      } else {
        this.isDisabled = false
        // this.form.label = ''
        // this.form.placeholder = ''
      }
      if (type === '单行文本') {
        this.form.minCount = 1
        this.form.maxCount = 500
        return false
      }
      if (type === '数字') {
        this.form.maxCount = null
        this.form.minCount = null
        return false
      }
      if (type === '多项选择') {
        this.form.minCount = 1
        this.form.maxCount = null
        return false
      }
      if (type === '团队') {
        this.form.label = '团队'
        return false
      }
      if (type === '多张图片') {
        this.form.maxCount = 3
      } else {
        this.form.maxCount = 1
      }
    },
    async handlePreview(pointPhoto) {
      if (!pointPhoto.url && !pointPhoto.preview) {
        pointPhoto.preview = await getBase64(pointPhoto.originFileObj)
      }
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false
    },
    removeFile(file) {
      // const uid = file.uid
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    uploadFile(file, name) {
      const formData = new FormData()
      formData.append('file', file)
      const uid = file.uid
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            if (name === 'sampleGraph') {
              const obj = {
                uid: uid,
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
              const index = this.sampleGraphList.findIndex((item) => {
                return item.uid === uid
              })
              this.sampleGraphList.splice(index, 1, obj)
            }
            if (name === 'photoDesc') {
              const obj = {
                uid: uid,
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
              const index = this.photoDescList.findIndex((item) => {
                return item.uid === uid
              })
              this.photoDescList.splice(index, 1, obj)
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    // 选择类型改变
    selectTypeChange(e) {
      console.log('change', e, this.form.referrerFieldId)
      const that = this
      if (that.form.referrerFieldId) {
        Modal.confirm({
          title: '是否要更改字段类型，更改后可能造成数据显示错误!',
          icon: createVNode(ExclamationCircleOutlined),
          content: createVNode('div', { style: 'color:red;' }, '是否继续切换?'),
          onOk() {
            that.form.type = e
            that.isAddOther = false
            that.sampleGraphList = []
            that.photoDescList = []
            that.initFormData(e)
          },
          onCancel() {}
        })
      } else {
        that.form.type = e
        that.isAddOther = false
        that.sampleGraphList = []
        that.photoDescList = []
        that.initFormData(e)
      }
    },
    petition(arr) {
      var tempbool = false // 默认无重复
      for (let index = 0; index < arr.length; index++) {
        for (let i = index + 1; i < arr.length; i++) {
          if (arr[index] === arr[i]) {
            tempbool = true
          }
        }
      }
      if (tempbool) {
        console.log('存在重复数据！')
      }
      return tempbool
    },
    addListData() {
      const obj = {
        label: '',
        id: null
      }
      this.listData.push(obj)
    },
    removeListData(item, index) {
      if (item.label === '其他') {
        this.listData.splice(index, 1)
        this.isAddOther = false
      } else {
        this.listData.splice(index, 1)
      }
    },
    addOther() {
      this.isAddOther = true
      this.listData.push({ label: '其他', id: null })
    },
    /* 保存编辑 */
    save() {
      if (this.listDataType === 'dataSource') {
        this.listData = []
      }
      if (this.listDataType === 'custom') {
        this.form.dataSource = ''
      }
      if (this.isExistSameField() && !this.isUpdate) {
        this.$message.error('已存在该字段!请勿重复添加')
        return false
      }
      if (
        this.petition(
          this.listData.map((item) => {
            return item.label
          })
        )
      ) {
        this.$message.error('选项中存在相同数据!')
        return false
      }
      this.$refs.form
        .validate()
        .then(() => {
          if (!this.isUpdate) {
            this.form.custom = true
            this.form.enable = true
          }
          this.form.listData = this.arrToStr(
            this.listData.map((item) => {
              return item.label
            })
          )
          this.form.photoDesc = this.arrToStr(
            this.photoDescList.map((item) => {
              return item.url
            })
          )
          this.form.sampleGraph = this.arrToStr(
            this.sampleGraphList.map((item) => {
              return item.url
            })
          )
          this.$emit('done', this.form, this.isUpdate)
        })
        .catch(() => {})
    },
    // 判断是否存在相同的字段
    isExistSameField() {
      const labelArr = this.fieldList.map((item) => {
        return item.label
      })
      return labelArr.includes(this.form.label)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style lang="less" scoped>
.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.author-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d6d7d9;
  margin: 20px 0;
  padding: 10px 20px;

  .author-content {
    width: 800px;

    .author-item {
      display: flex;
      width: 100%;
      margin-bottom: 10px;

      .value {
        width: 80%;
        white-space: wrap;
        color: #9b9b9b;
      }
    }
  }

  .title {
    display: inline-block;
    width: 40px;
    margin-right: 5px;
  }
}
</style>
